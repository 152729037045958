<template>
    <div class="rt-wraper">
        <b-link :to="{name: 'auth-login'}" class="logo">
            <vuexy-logo/>
        </b-link>
        <h1>Privacy Notice</h1>
        <p>The purpose of this notice is to be transparent about how and why we use your personal data when using the ‘AML Complete’ platform and to ensure that you are aware of your rights under UK data protection legislation (UK General Data Protection Regulation (UK GDPR), Data Protection Act 2018).</p>
        <div class="heading">The Company</div>
        <p>We are the Institute of Accountants and Bookkeepers and we are the data controller. Our correspondence address is Unit 1, Moat Farm, The Oast, Whetsted Road, Five Oak Green, Tonbridge, Kent, TN12 6RR You can contact us at hello@iab.org.uk or on 0208 187 8888.</p>
        <div class="heading">The purpose for processing your data and our basis for doing so.</div>
        <p>We process personal data on ‘AML Complete’ to so that we can effectively discharge our supervisory obligations under The Money Laundering, Terrorist Financing and Transfer of Funds (Information on the Payer) Regulations 2017. In processing your data, and that of your clients and staff, we must establish our lawful basis under the UK GDPR for doing so. That lawful basis is detailed below.</p>
        <p>The ‘AML Complete’ platform requires the input of information relating to your practice and that of your clients and staff. Some of the information will be classed as personal data but we only require that personal data which is specifically required and limited for the purpose to which we process it. Our lawful basis for processing this personal data is Article 6.1.c – legal obligation. We have a legal obligation to process personal data to comply with the Money Laundering Regulations.</p>
        <p>We will also access personal data placed on the ‘AML Complete’ for our membership administration purposes, in relation to continuous professional development requirements. For this specific processing purpose, our lawful basis is Article 6.1.b – performance of a contract.</p>
        <p>Please note, if you fail to provide the required data, we will not be able to act as your Professional Body Supervisor.</p>
        <div class="heading">Recipients of your data</div>
        <p>As a general principle, we will not transfer your personal data to other recipients without your permission. There are some exceptions to this:</p>
        <ul>
          <li>We are obliged to report any suspicions of money laundering to the National Crime Agency under Regulation 46(5) of the Money Laundering Regulations 2017. Our lawful basis for doing this is Article 6.1.c – legal obligation.</li>
          <li>It is possible, though unlikely, that we might be required to disclose your information in response to a request from law enforcement agencies, a court order or other legal requirement. Our lawful basis for doing this is Article 6.1.c - Legal obligation.</li>
          <li>We may share your personal data with other Professional Body Supervisors in relation to your supervision by us as required by Regulation 49(3) of the Money Laundering Regulations 2017. The lawful basis for doing this is Article 6.1.b – legal obligation</li>
        </ul>
        <div class="heading">Data processed by third parties on our behalf.</div>
        <p>We use the services of other organisations in the processing your data. This will be our cloud based hosting centres, web developers and specialist consultants. These 3rd party processors are contracted by the Institute of Accountants and Bookkeepers and there are Data Processing Agreements in place with them as required by Article 28 of the UK GPDR.</p>
        <div class="heading">Transferring your data outside of the UK</div>
        <p>Your personal data is stored in the UK</p>
        <div class="heading">Retention periods</div>
        <p>We will retain your data only for the time we require it for the purposes stated and / or where we have a legal obligation or other legitimate purpose. To allow us to comply with our legal obligations in relation to supervision and reporting to the FCA Regulator, we will retain personal data held on the platform for 365 days before it is deleted.</p>
        <div class="heading">Your rights</div>
        <p>The UK GDPR provides you with several rights in relation to the data we process. The rights relevant to our activities are:</p>
        <ul>
          <li>You have the right to get access to and copies of your personal data.</li>
          <li>You can ask us to rectify any inaccurate information we may be holding.</li>
          <li>You can restrict our processing of your data</li>
          <li>You have the right of data portability</li>
        </ul>
        <p>If you want to exercise any of these rights, contact us on the above email address.</p>
        <p>You also have the right to lodge a complaint about our processing with a supervisory authority — the UK's Information Commissioner's Office.</p>
        <p>Information Commissioner's Office<br/>Wycliffe House<br/>Water Lane<br/>Wilmslow<br/>Cheshire<br/>SK9 5AF<br/>Telephone: 0303 123 1113<br/>Website: www.ico.org.uk</p>
    </div>
</template>
<script>
    import VuexyLogo from '@core/layouts/components/Logo.vue'
    import {BLink} from 'bootstrap-vue'
    export default {
        components: {
            BLink,
            VuexyLogo
        }
    }
</script>
<style lang="scss">
    .rt-wraper{padding:30px 40px;display:flex;flex-direction:column;}
    .rt-wraper .logo{align-self:flex-start;margin-bottom:20px;}
    .rt-wraper .logo svg{height:43px;width:160px;}
    .rt-wraper h1{margin:0 0 20px;color:#222;font-size:24px;font-weight:600;}
    .rt-wraper .heading{margin:0 0 10px;color:#222;font-size:18px;line-height:26px;font-weight:600;}
    .rt-wraper ul{color:#222;}
    .rt-wraper ul li{color:#222;margin-bottom:15px;font-size:16px;line-height:26px;font-weight:400;}
    .rt-wraper p{color:#222;margin-bottom:15px;font-size:16px;line-height:26px;font-weight:400;}
</style>
<style lang="scss">
    @import '@core/scss/vue/pages/page-auth.scss';
</style>